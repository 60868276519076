const hamburgerBtn = document.querySelector(
	'.nav__hamburger-box'
) as HTMLButtonElement
const nav = document.querySelector('.nav__items') as HTMLDivElement
const navItems = document.querySelectorAll('.nav__item')
const year = document.querySelector('.year') as HTMLSpanElement

const handleNav = (): void => {
	hamburgerBtn.classList.toggle('nav__hamburger-box--active')
	nav.classList.toggle('nav__items--active')
	navItems.forEach(item => item.classList.toggle('nav__item--active'))
	if (window.innerWidth < 768) {
		document.body.classList.toggle('sticky-body')
	}
}

const closeNav = (): void => {
	hamburgerBtn.classList.remove('nav__hamburger-box--active')
	nav.classList.remove('nav__items--active')
	navItems.forEach(item => item.classList.remove('nav__item--active'))
	document.body.classList.remove('sticky-body')
}

const setCurrentYear = (): void => {
	const date: number = new Date().getFullYear()
	year.textContent = date.toString()
}

setCurrentYear()
hamburgerBtn.addEventListener('click', handleNav)
navItems.forEach(item => item.addEventListener('click', closeNav))